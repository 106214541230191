<template>
	<v-container>
		<v-row>
			<v-col>
				<s-crud
					add
					edit
					@save="save($event)"
					remove
					:config="config"
					:filter="filter"
					title="Motivos de paradas por Área"
				>
					<template v-slot:filter>
							<v-row justify="center" style="margin:auto">
								<v-col cols="12" lg="2" md="4">
									<s-select-definition
										label="Nave"
										:def="1342"
										v-model="filter.TypeShip"
										@input="inputShip($event)"
									></s-select-definition>
								</v-col>
								<v-col cols="6" lg="4" md="4">
									<s-select-definition
										label="Area"
										:def="def"
										:dgr="dgr"
										v-model="filter.TypeArea"
									/>
								</v-col >
							</v-row>
					</template>
					<template scope="props">
						<v-container>
							<v-row>
								<v-col cols="12" lg="2" md="4">
									<s-select-definition
										label="Nave"
										:def="1342"
										v-model="props.item.TypeShip"
										@input="inputShip($event)"
									></s-select-definition>
								</v-col>

								<v-col cols="6" lg="2" md="4">
									<s-select-definition
										label="Area"
										:def="def"
										:dgr="dgr"
										v-model="props.item.TypeArea"
									/>
								</v-col >
								<v-col cols="6" lg="2" md="4">
									<s-select-definition
										label="Tipo de Parada"
										:def="1313"
										v-model="props.item.TypeStop"
									/>
								</v-col>
								<v-col cols="12" lg="6" md="12">
									<s-text
										label="Motivo"
										v-model="props.item.RstDescription"
									></s-text>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</s-crud>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import _sTurnStopReason from "@/services/FreshProduction/PrfTurnStopReasonService.js";
	export default {
		props: {},
		data: () => ({
			filter: {TypeArea: 0},
			config: {
				model: {
					RstID: "ID",
				},
				headers: [
					{ text: "ID", value: "RstID" },
					{ text: "Nave", value: "TypeShipName" },
					{ text: "Area", value: "TypeAreaName" },
					{ text: "Motivo", value: "RstDescription" },
				],
				service: _sTurnStopReason,
			},
			dgr: 40,
			def: 1281,
		}),

		created() {},

		methods: {
			inputShip(item) {
				if (item == 1) {
					this.def = 1281;
					this.dgr = 40;
				}

				if (item == 2) {
					this.def = 1348;
					this.dgr = 41;
				}
			},

			save(item) {
				if (item.RstDescription.length > 0) {
					if (item.RstID == null || item.RstID == 0) {
						item.SecStatus = 1;
						item.RstStatus = 1;
						item.UsrCreateID = this.$fun.getUserID();
					}

					item.UsrUpdateID = this.$fun.getUserID();

					item.save();
				} else {
					this.$fun.alert("Ingrese Descripción", "warning");
				}
			},
		},
	};
</script>
